<template>
  <v-container>
    <v-row class="sc-title mt-7 mb-6">
      <v-col md="8" sm="6">
        <div>
          <h1>Rep Training</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn color="primary" @click="$router.push({ name: 'Evaluation' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <h5>Coming Soon</h5>
  </v-container>
</template>

<script>
export default {
  name: "RepTraining",
  data() {
    return {};
  },
};
</script>

<style scoped></style>
